function IconDocument() {
	return (
		<>
			<svg
				width='20'
				height='20'
				viewBox='0 0 20 20'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<g clipPath='url(#clip0_2350_54099-754018)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M17.5129 3.75L11.7557 3.73714L9.84714 1.82857C9.46174 1.4675 8.95657 1.26146 8.42857 1.25H2.49C1.82881 1.25302 1.19571 1.5177 0.72911 1.98617C0.262511 2.45465 0.000370711 3.0888 0 3.75L0 16.25C0.00264419 16.9137 0.268061 17.5494 0.738182 18.0179C1.2083 18.4864 1.84485 18.7496 2.50857 18.75H17.4914C17.8206 18.7515 18.1467 18.6879 18.4512 18.5629C18.7557 18.4379 19.0325 18.254 19.2656 18.0216C19.4987 17.7893 19.6836 17.5132 19.8097 17.2091C19.9357 16.9051 20.0004 16.5791 20 16.25V6.25C20 5.58918 19.7384 4.95524 19.2723 4.48676C18.8063 4.01829 18.1737 3.7534 17.5129 3.75V3.75ZM16.9329 14.3971C16.9336 14.6072 16.8929 14.8154 16.8131 15.0098C16.7332 15.2041 16.6158 15.3808 16.4676 15.5297C16.3194 15.6786 16.1432 15.7968 15.9492 15.8775C15.7552 15.9582 15.5472 15.9998 15.3371 16H4.66286C4.23976 15.9996 3.83411 15.8314 3.53493 15.5322C3.23576 15.233 3.06752 14.8274 3.06714 14.4043V10.1986C3.06752 9.77548 3.23576 9.36982 3.53493 9.07065C3.83411 8.77148 4.23976 8.60324 4.66286 8.60286H15.3371C15.7602 8.60324 16.1659 8.77148 16.4651 9.07065C16.7642 9.36982 16.9325 9.77548 16.9329 10.1986V14.3971Z'
						fill='white'
					/>
				</g>
				<defs>
					<clipPath id='clip0_2350_54099-754018'>
						<rect width='20' height='20' fill='white' />
					</clipPath>
				</defs>
			</svg>
		</>
	)
}

export { IconDocument }
