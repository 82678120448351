import { Link } from 'react-router-dom'

import { AnchorLink } from '../../../AnchorLink'

function CaseItem(props: any) {
	return (
		<div
			className={`cases-slider__slide-item cases-item cases-item--${
				props.name
			} ${props.isActive && 'is-active'}`}
			key={props.index}
		>
			<div className='cases-item__number'>
				<span>{props.counter < 10 ? `0${props.counter}` : props.counter}</span>
				<div className='cases-item__number-decor'>
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div>

			{props.award && (
				<AnchorLink id='awards'>
					<div className='cases-item__award'>
						<img src={props.award} alt='cases-item__award' />
					</div>
				</AnchorLink>
			)}

			<div className='cases-item__img'>
				<img src={props.img} alt='cases-item__img' />
			</div>

			<div className='cases-item__txt'>
				{props.title}
				<span style={{ color: `${props.colorSpan}` }}>{props.titleSpan}</span>
			</div>

			<Link
				to={props.link ? `/${props.link}` : `#`}
				className={`cases-item__btn ${
					props.link ? `` : `cases-item__btn-disabled`
				}`}
			>
				<span>{props.link ? 'ПОДРОБНЕЕ' : 'В разработке'}</span>
			</Link>
		</div>
	)
}

export { CaseItem }
