import { useEffect } from 'react'

import { Header } from '../../components/Header'
// import { HeroLgUltraGear } from '../../components/ContentBlocks/HeroLgUltraGear'
import { SolutionTemplate } from '../../components/ContentBlocks/templates/SolutionTemplate'
import { Footer } from '../../components/Footer'

function FaceitVisaGazprombank() {
	useEffect(() => {
		document.title =
			'No Sleep Agency — Организация клиентской поддержки для держателей карты FACEIT, Visa, Газпромбанк'
		window.scrollTo(0, 0)
	}, [])

	const DataResult = [
		{
			id: 1,
			statValue: `3 515 998`,
			statName: `Охвата целевой аудитории`,
		},
		{
			id: 2,
			statValue: `646 422`,
			statName: `Комментариев`,
		},
		{
			id: 3,
			statValue: `18 760`,
			statName: `Подписчиков `,
		},
		{
			id: 4,
			statValue: `30%`,
			statName: `Увеличение ER на`,
		},
	]

	const ContentText = `Мы разработали чат-бота и создали игру, основываясь на образе
						амбассадора — киберспортсмена в дисциплине Fortnite Марка
						Letw1k3 Данилова. Были выполнены задачи по созданию дизайна,
						механики и разработке контента для сообщества, связанного со
						спецпроектом.\nПользователи набирали баллы за активность в
						сообществе UltraGear HUB (лайки, комментарии) — вся игра была
						оформлена в стиле классической механики прокачки персонажа в
						играх: каждый участник получал своего аватара, уровень которого
						повышался, когда достигалось нужное количество баллов. После
						взятия каждого уровня, внешний вид персонажа менялся на более
						эффектный. Еженедельно самые активные участники премировались
						внутриигровыми предметами для Fortnite, а по достижении
						максимального уровня, пользователь получал возможность
						поучаствовать в розыгрыше с более крупными призами.`

	return (
		<>
			<div className='wrapper'>
				<Header />

				<main>
					{/* <HeroLgUltraGear /> */}
					{/* <SolutionTemplate
						image={`image`}
						contentText={ContentText}
						dataResult={DataResult}
					/> */}
				</main>

				<Footer />
			</div>
		</>
	)
}

export default FaceitVisaGazprombank
