import './HeroGoods.scss'

import { Breadcrumbs } from '../../Breadcrumbs'

import M4 from '../../../static/img/hero-goods/m4a1.png'
import DecBigMin from '../../../static/img/hero-goods/dec-big-min.png'
import TasksDecMin from '../../../static/img/tasks/tasks-dec-min.png'

function HeroGoods() {
	return (
		<>
			<section className='hero-goods'>
				<div className='hero-goods__decor'>
					<img src={DecBigMin} alt='dec-big-min' />
				</div>
				<div className='hero-goods__decor-m4'>
					<img src={M4} alt='m4a1' />
				</div>
				<div className='container'>
					<Breadcrumbs />

					<h2 className='hero-goods__title'>
						<span className='hero-goods__title-span1'>Геймификация </span>
						<span className='hero-goods__title-span2'>в маркетплейсе</span>
						<span className='hero-goods__title-span3'>Goods.ru</span>
					</h2>

					<div className='tasks'>
						<div className='tasks__decor-goods'>
							<img src={TasksDecMin} alt='tasks-dec-min' />
						</div>
						<div className='tasks__title'>
							<p>Задачи</p>
						</div>
						<div className='tasks-list'>
							<div className='tasks-list__item'>
								<p className='tasks-list__item-txt'>
									Охватить аудиторию киберспорта и гейминга
								</p>
							</div>
							<div className='tasks-list__item'>
								<p className='tasks-list__item-txt'>
									Нарастить клиентскую базу
								</p>
							</div>
							<div className='tasks-list__item'>
								<p className='tasks-list__item-txt'>
									Привлечь подписчиков в новое тематическое сообщество ВКонтакте
								</p>
							</div>
							<div className='tasks-list__item'>
								<p className='tasks-list__item-txt'>
									Помочь конвертировать привлеченных пользователей в клиентов
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export { HeroGoods }
