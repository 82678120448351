import './HeroFks.scss'

import { Breadcrumbs } from '../../Breadcrumbs'

import HeroFksDec from '../../../static/img/hero-fks/hero-fks-dec-min.png'
import CupSmallMin from '../../../static/img/hero-fks/cup-small-min.png'
import CupBigMin from '../../../static/img/hero-fks/cup-big-min.png'
import LogoFksMin from '../../../static/img/hero-fks/logo-fks-min.png'

function HeroFks() {
	return (
		<>
			<section className='hero-fks'>
				<div className='hero-fks__decor'>
					<img src={HeroFksDec} alt='hero-fks-dec-min' />
				</div>
				<div className='container'>
					<Breadcrumbs />

					<div className='hero-fks-content'>
						<div className='hero-fks-content__info hero-fks-content__item'>
							<h2
								className='hero-fks__title'
								style={{ transform: `translate(0px, 0px)`, opacity: 1 }}
							>
								No Sleep Agency <span>x ФКС</span>
							</h2>
							<div
								className='hero-fks__txt is-active'
								style={{ transform: `translate(0px, 0px)`, opacity: 1 }}
							>
								<p>
									Как за 5 дней привлечь внимание юных киберспортсменов к
									турниру, организованному ФКС России.
								</p>
							</div>
						</div>
						<div className='hero-fks-content__img hero-fks-content__item'>
							<div className='hero-fks-content__img-cup-small hero-fks-content__img-item'>
								<img
									className='js-fly-anim'
									src={CupSmallMin}
									alt='cup-small-min'
									style={{
										transform: `translate3d(0.13px, 2.6747px, 0px) rotate(-0.253deg)`,
									}}
								/>
							</div>
							<div className='hero-fks-content__img-logo hero-fks-content__img-item'>
								<img
									className='js-fly-anim'
									src={LogoFksMin}
									alt='logo-fks-min'
									style={{
										transform: `translate3d(-1.1532px, 5.8653px, 0px) rotate(-0.2596deg)`,
									}}
								/>
							</div>
							<div className='hero-fks-content__img-cup-big hero-fks-content__img-item'>
								<img
									className='js-fly-anim'
									src={CupBigMin}
									alt='cup-big-min'
									style={{
										transform: `translate3d(-5.2854px, -6.3115px, 0px) rotate(-0.7645deg)`,
									}}
								/>
							</div>
							<span className='hero-fks-content__img-strip hero-fks-content__img-strip--1'></span>
							<span className='hero-fks-content__img-strip hero-fks-content__img-strip--2'></span>
							<span className='hero-fks-content__img-strip hero-fks-content__img-strip--3'></span>
						</div>
					</div>

					<div className='tasks'>
						<div className='tasks__title'>
							<p>Задачи</p>
						</div>
						<div className='tasks-list'>
							<div className='tasks-list__item'>
								<p className='tasks-list__item-txt'>
									Привлечь внимание аудитории игровых блогеров
								</p>
							</div>
							<div className='tasks-list__item'>
								<p className='tasks-list__item-txt'>
									Увеличить количество регистраций на турнир
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export { HeroFks }
