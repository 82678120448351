import React, { ReactNode } from 'react'

import './SolutionTemplate.scss'

interface ISolutionTemplate {
	name: string
	solutionText: string
	solutionNote?: ReactNode
	dataResult: {
		statValue: string
		statName: string
	}[]
	image?: any
	decor?: any
	children?: any
}

const SolutionTemplate: React.FC<ISolutionTemplate> = props => {
	const dataResult = props.dataResult

	return (
		<>
			<section className={`solution-template solution-template__${props.name}`}>
				{props.decor && (
					<div className='solution-template__decor'>{props.decor}</div>
				)}

				<div className='container'>
					<h2 className='solution-template__title'>Решение</h2>

					<div className='solution-template-content'>
						<div className='solution-template-content__img'>
							{props.children}
						</div>

						<div className='solution-template-content__txt'>
							<p className='solution-template-content__txt-p'>
								{props.solutionText || 'solutionText'}
							</p>

							<h4 className='solution-template-content__txt-title'>
								Результаты <span></span>
							</h4>

							{props.solutionNote && (
								<p className='solution-template-content__txt-note'>
									{props.solutionNote}
								</p>
							)}

							<div className='solution-template-content__txt-stat'>
								{dataResult.map(({ statValue, statName }, index) => (
									<p
										key={index}
										className='solution-template-content__txt-stat-p'
									>
										<span>{statValue}</span>
										{statName}
									</p>
								))}
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export { SolutionTemplate }
