import './TasksTemplate.scss'

interface ITasksTemplate {
	name: string
	dataTasks: {
		task: string
		oddElement?: boolean
	}[]
	styleSection?: React.CSSProperties
	decor?: any
}

const TasksTemplate: React.FC<ITasksTemplate> = props => {
	const dataTasks = props.dataTasks

	return (
		<>
			<section
				className={`tasks-template tasks-template__${props.name}`}
				style={props.styleSection}
			>
				<div className='container'>
					{props.decor && (
						<div className='tasks-template__decor'>{props.decor}</div>
					)}

					<div className='tasks-template__title'>
						<p>Задачи</p>
					</div>

					<div className='tasks-template-list'>
						{dataTasks.map(({ task, oddElement }, index) => (
							<div
								key={index}
								className={`tasks-template-list__item ${
									oddElement ? 'tasks-template-list__item-odd-element' : ``
								}`}
							>
								<p className='tasks-template-list__item-txt'>{task}</p>
							</div>
						))}
					</div>
				</div>
			</section>
		</>
	)
}

export { TasksTemplate }
