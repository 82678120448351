import './Solution.scss'

import SolDecMin from '../../../static/img/solution/sol-dec-min.png'
import SolutionMin from '../../../static/img/solution/solution-min.png'
import Federation from '../../../static/img/solution/federation.svg'

function Solution() {
	return (
		<>
			<section className='solution'>
				<section className='solution'>
					<div className='solution__decor'>
						<img src={SolDecMin} alt='sol-dec-min' />
					</div>
					<div className='container'>
						<h2 className='solution__title'>Решение</h2>
						<div className='solution-content'>
							<div className='solution-content__img'>
								<img src={SolutionMin} alt='solution-min' />
							</div>
							<div className='solution-content__txt'>
								<p className='solution-content__txt-p'>
									ФКС России является аккредитованной Министерством спорта
									Российской Федерации общероссийской спортивной федерацией и
									занимается развитием киберспорта на территории России с 2000
									года. Ежегодно ФКС проводит киберспортивный турнир среди
									учащихся школ по всей стране. В сезоне 2021 года будет
									разыгран призовой фонд в размере 1 000 000 рублей, а также
									возможность принять участие в съемках мини-фильма от студии
									RuHub о команде победителей. Соревнования проходят по таким
									дисциплинам как Dota 2, Hearthstone, электронные шахматы,
									поиск в интернете на время и Clash Royale.
								</p>
								<p className='solution-content__txt-p'>
									Наша главная цель состояла в привлечении максимального
									количества участников за 5 дней (целевое действие: регистрация
									через кнопку на сайте соревнований). Для продвижения
									мероприятия мы сделали выбор в пользу популярной у целевой
									аудитории площадки — TikTok. Интеграция в контент релевантных
									блогеров стала эффективным механизмом для заявления о
									соревнованиях.
								</p>
								<p className='solution-content__txt-p'>
									Мы привлекли 5 игровых TikTok-креаторов с подходящей
									аудиторией. В проекте участвовали LLLuiko, Flaxxgod,
									Prepodsteam, Gamercsgo и CSgotrend. Общее количество их
									подписчиков составляет 3,7 млн.
								</p>
								<p className='solution-content__txt-p'>
									На своих каналах они рассказывают про тренды, лайфхаки и
									различные события в игровой индустрии, чем как раз и интересны
									молодым киберспортсменам. В своих видео блогеры анонсировали
									старт регистрации на турнир и рассказали подписчикам об
									остальных необходимых подробностях для участия в нем. Всего
									было выпущено 13 видео на 5 каналах разных инфлюенсеров.
									Выбранный инструмент продвижения позволил достичь
									максимального охвата и перевыполнить KPI за 5 дней.
								</p>
							</div>
						</div>
					</div>
					<div className='solution__letters'>
						<div
							style={{
								width: 100000,
								transform: `translateX(0px)`,
								animation: `15.2365s linear 0s infinite normal none running marqueeAnimation-186074`,
							}}
							className='js-marquee-wrapper'
						>
							<div
								className='js-marquee'
								style={{ marginRight: 30, float: 'left' }}
							>
								<img src={Federation} alt='federation' />
								<img src={Federation} alt='federation' />
							</div>
							<div
								className='js-marquee'
								style={{ marginRight: 30, float: 'left' }}
							>
								<img src={Federation} alt='federation' />
								<img src={Federation} alt='federation' />
							</div>
						</div>
					</div>
				</section>
			</section>
		</>
	)
}

export { Solution }
