import { useEffect } from 'react'

import { Header } from '../components/Header'
import { Footer } from '../components/Footer'

function NotFound() {
	useEffect(() => {
		document.title = 'Страница не найдена — No Sleep Agency'
	}, [])

	return (
		<>
			<div className='wrapper'>
				<Header />

				<main>
					<h1>NotFound</h1>
				</main>

				<Footer />
			</div>
		</>
	)
}

export default NotFound
