import './HeroTemplate.scss'

import { Breadcrumbs } from '../../../Breadcrumbs'

interface IHeroTemplate {
	name: string
	formatTextImage?: boolean
	decor?: any
	children?: any
}

const HeroTemplate: React.FC<IHeroTemplate> = props => {
	return (
		<>
			<section
				className={`hero-template hero-template__${props.name} ${
					props.formatTextImage ? `hero-template__format--text-img` : ``
				}`}
			>
				{props.decor && (
					<div className='hero-template__decor'>{props.decor}</div>
				)}

				<div className='container'>
					<Breadcrumbs />

					<div className='hero-template__content'>{props.children}</div>
				</div>
			</section>
		</>
	)
}

export { HeroTemplate }
