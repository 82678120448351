import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import styles from './ContactsPage.module.scss'

import { Button } from '../../Buttons/Button'
import { FormInput } from '../../FormFields/FormInput'
import { FormTextarea } from '../../FormFields/FormTextarea'

import ContactsDec from '../../../static/img/hero/dec1-min.png'
import ContactsDec2 from '../../../static/img/hero-goods/dec-big-min.png'

import { IconEmail } from '../../../static/img/svg/IconEmail'

function ContactsPage() {
	const sendToTg = async (txt: string) => {
		await fetch(
			`https://api.telegram.org/bot6130887297:AAGWZQTCouVSf12qSm0QMvOMIcDPEHMZn34/sendMessage?chat_id=-623121348&parse_mode=html&text=${txt}`
		)
	}

	const [sent, setSent] = useState(false)

	const [inputValues, setInputValues] = useState({
		name: '',
		email: '',
		phone: '',
		task: '',
	})

	const handleOnChange = (event: any) => {
		const { name, value } = event.target
		console.log(name)
		console.log(value)
		setInputValues({ ...inputValues, [name]: value })
	}

	const sendForm = async () => {
		if (sent) return
		setSent(true)
		await sendToTg(
			`<b>Новая завяка от ${inputValues.name}</b>%0A` +
				`<b>Телефон: ${inputValues.phone}</b>%0A` +
				`<b>Email: ${inputValues.email}</b>%0A` +
				`<b>Сфера/цели/задачи: ${inputValues.task}</b>`
		)

		alert('Успешно отправлено')

		setTimeout(() => {
			window.location.reload()
			// window.scrollTo(0, 0)
		}, 300)
	}

	// validation

	const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

	const {
		register,
		formState: { errors, isValid },
		handleSubmit,
		reset,
	} = useForm({ mode: 'onBlur' })

	const onSubmit = () => {
		reset()
	}

	return (
		<>
			<section className={styles.root}>
				<div className={styles.decoration}>
					<img
						className={styles.decorationImg}
						src={ContactsDec}
						alt='ContactsDec'
					/>
					<img
						className={styles.decorationImg2}
						src={ContactsDec2}
						alt='ContactsDec2'
					/>
				</div>

				<div className={`container ${styles.container}`}>
					<h2>КОНТАКТЫ</h2>

					<div className={styles.wrapper}>
						<div className={styles.info}>
							<div className={styles.block}>
								<h4>Почта:</h4>
								<div className={styles.item}>
									<h6>По общим вопросам</h6>
									<Link target='_blank' to='mailto:info@nosleepagency.ru'>
										<IconEmail />
										<h5 className='link'>info@nosleepagency.ru</h5>
									</Link>
								</div>
								<div className={styles.item}>
									<h6>Тендеры и новые проекты по направлениям</h6>
									<Link target='_blank' to='mailto:tender@nosleepagency.ru'>
										<IconEmail />
										<h5 className='link'>tender@nosleepagency.ru</h5>
									</Link>
								</div>
							</div>
							<div className={styles.block}>
								<h4>График работы:</h4>
								<h5>с 10:00 до 19:00 Сб, Вс — выходной</h5>
							</div>
							<div className={styles.block}>
								<h4>Реквизиты:</h4>
								<h5>
									{`Общество с ограниченной ответственностью “Гуд Гейм” \nОГРН 1187746461110 \nИНН / КПП 9701108760 / 770101001 \nРасч. счет 40702810002680002926 \nКорр. счет 30101810200000000593 \nБИК 044525593 \nГенеральный директор: Смирнов Виталий Юрьевич`}
								</h5>
							</div>
						</div>

						<form onSubmit={handleSubmit(onSubmit)} className='form-feedback'>
							<div className={styles.form}>
								<div className={styles.header}>
									<h5>Расскажите нам о своем проекте</h5>
									<h6>
										Пожалуйста, опишите проект как можно подробнее, а мы поможем
										сформировать задачу, оценим проект и расскажем о процессе.
									</h6>
									<h6 className={styles.note}>
										(*) — обязательные поля для заполнения
									</h6>
								</div>

								<div className={styles.fields}>
									<FormTextarea
										placeholder='* Сфера деятельности, цели и задачи проекта'
										id='task'
										value={inputValues.task}
										register={register('task', {
											required:
												'Напишите cферу деятельности, цели и задачи проекта',
											onChange: e => handleOnChange(e),
										})}
										error={errors?.task}
									/>
									<FormInput
										placeholder='* Как к вам обращаться?'
										type='text'
										value={inputValues.name}
										register={register('name', {
											required: 'Напишите ваше имя',
											onChange: e => handleOnChange(e),
										})}
										onChange={handleOnChange}
										error={errors?.name}
									/>
									<FormInput
										placeholder='* E-mail'
										type='email'
										value={inputValues.email}
										register={register('email', {
											required: 'Напишите ваш e-mail',
											onChange: e => handleOnChange(e),
											pattern: {
												value: emailPattern,
												message: 'Введите корректный email',
											},
										})}
										error={errors?.email}
									/>
									<FormInput
										placeholder='Телефон'
										type='tel'
										name='phone'
										value={inputValues.phone}
										onChange={handleOnChange}
									/>

									<Button
										type='submit'
										onClick={sendForm}
										value='Отправить'
										disabled={!isValid}
									/>
								</div>

								<div className={styles.footer}>
									<h6>
										Нажимая на кнопку, вы даете согласие на обработку
										персональных данных и соглашаетесь с политикой
										конфиденциальности.
									</h6>
								</div>
							</div>
						</form>
					</div>
				</div>
			</section>
		</>
	)
}

export { ContactsPage }
