function IconEmail() {
	return (
		<>
			<svg
				width='20'
				height='20'
				viewBox='0 0 20 20'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<g clipPath='url(#clip0_2344_54098-579110)'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M20 4.20138C20 3.70884 19.8043 3.23646 19.4561 2.88818C19.1078 2.5399 18.6354 2.34424 18.1429 2.34424H1.85714C1.3646 2.34424 0.892226 2.5399 0.543945 2.88818C0.195663 3.23646 0 3.70884 0 4.20138L0 15.7985C0 16.2911 0.195663 16.7634 0.543945 17.1117C0.892226 17.46 1.3646 17.6557 1.85714 17.6557H18.1429C18.6354 17.6557 19.1078 17.46 19.4561 17.1117C19.8043 16.7634 20 16.2911 20 15.7985V4.20138ZM9.48 11.5485L3.85714 6.19567C3.76331 6.10316 3.7001 5.98411 3.67603 5.85457C3.65196 5.72502 3.66818 5.59121 3.72252 5.47117C3.77686 5.35114 3.86669 5.25064 3.97991 5.18325C4.09313 5.11585 4.22429 5.08478 4.35571 5.09424H15.6486C15.7804 5.08426 15.912 5.11515 16.0256 5.18269C16.1392 5.25024 16.2293 5.35116 16.2834 5.47171C16.3376 5.59226 16.3533 5.72658 16.3284 5.85638C16.3035 5.98618 16.2392 6.10516 16.1443 6.1971L10.4743 11.55C10.3386 11.6744 10.161 11.7434 9.97686 11.7431C9.7927 11.7429 9.61536 11.6734 9.48 11.5485V11.5485Z'
						fill='black'
					/>
				</g>
				<defs>
					<clipPath id='clip0_2344_54098-579110'>
						<rect width='20' height='20' fill='white' />
					</clipPath>
				</defs>
			</svg>
		</>
	)
}

export { IconEmail }
