import styles from './AwardItem.module.scss'

function AwardItem(props: any) {
	return (
		<>
			<div className={styles.root}>
				<div className={styles.wrapper}>
					<div className={styles.header}>
						<span>{props.header || 'header'}</span>
					</div>
					<div className={styles.body}>
						<div className={styles.image}>
							<img src={props.image} alt={props.image} />
						</div>
						<div className={styles.info}>
							<h3>{props.title || 'title'}</h3>
							<span>{props.awardName || 'award-Name'}</span>
							<h5>{props.footnote || `footnote`}</h5>

							{props.smLogo && (
								<div className={styles.smLogo}>
									<img src={props.smLogo} alt='smLogo' />
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export { AwardItem }
