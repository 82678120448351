import { useEffect } from 'react'

import { Header } from '../../components/Header'
import { Footer } from '../../components/Footer'
import { HeroGoods } from '../../components/ContentBlocks/HeroGoods'
import { Solution2 } from '../../components/ContentBlocks/Solution2'

function Goods() {
	useEffect(() => {
		document.title = 'No Sleep Agency — Геймификация в маркетплейсе Goods.ru'
		window.scrollTo(0, 0)
	}, [])

	return (
		<>
			<div className='wrapper'>
				<Header />

				<main>
					<HeroGoods />
					<Solution2 />
				</main>

				<Footer />
			</div>
		</>
	)
}

export default Goods
