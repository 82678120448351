import './Hero.scss'

import { AnchorLink } from '../../AnchorLink'

import HeroDec from '../../../static/img/hero/dec1-min.png'
import Letters from '../../../static/img/hero/letters.svg'
import LettersText from '../../../static/img/hero/nosleep-min.svg'
import LettersTextMobile from '../../../static/img/hero/nosleep-mob-min.svg'

import { IconArrowLong } from '../../../static/img/svg/IconArrowLong'

function Hero() {
	return (
		<>
			<section className='hero'>
				<div className='hero__dec hero__dec--1'>
					<img src={HeroDec} alt='HeroDec' />
				</div>
				<div className='container'>
					<div className='hero__go'>
						<span>GO!</span>
						<div className='hero__go-img'>
							<img src={Letters} alt='Letters' />
						</div>
					</div>
					<div className='hero-top'>
						<div className='hero-top__decor'>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
						</div>
						<h1 className='hero__title section-title'>
							<span>NO</span>
							<span className='hero__title-span'>SLEEP</span>
							{`\n AGENCY`}
						</h1>
					</div>
					<div className='hero-bottom'>
						<div className='hero-bottom__txt'>
							<p className='hero-bottom__txt-p'>
								Мы создаем проекты, которые заставляют проснуться
							</p>
							<AnchorLink className={`hero-bottom__link nav-link`} id='cases'>
								<IconArrowLong />
							</AnchorLink>
						</div>
						<div className='hero-bottom__letters'>
							<div className='js-marquee-wrapper'>
								<div
									className='js-marquee'
									style={{ marginRight: 0, float: 'left' }}
								>
									<img src={LettersText} alt='LettersText' />
									<img src={LettersText} alt='LettersText' />
								</div>
								<div
									className='js-marquee'
									style={{ marginRight: 0, float: 'left' }}
								>
									<img src={LettersText} alt='LettersText' />
									<img src={LettersText} alt='LettersText' />
								</div>
							</div>
						</div>
						<div className='hero-bottom__letters-mob'>
							<img src={LettersTextMobile} alt='LettersTextMobile' />
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export { Hero }
