import { useEffect } from 'react'

import { Header } from '../../components/Header'
import { HeroTemplate } from '../../components/ContentBlocks/templates/HeroTemplate'
import { TasksTemplate } from '../../components/ContentBlocks/templates/TasksTemplate'
import { SolutionTemplate } from '../../components/ContentBlocks/templates/SolutionTemplate'
import { Footer } from '../../components/Footer'

import ValorantHero from '../../static/img/cases/valorant/valorant-hero.png'
import ValorantLogos from '../../static/img/cases/valorant/logos.svg'
import ValorantSolution from '../../static/img/cases/valorant/valorant-solution.png'

function LgUltraGear() {
	useEffect(() => {
		document.title = 'No Sleep Agency — Valorant'
		window.scrollTo(0, 0)
	}, [])

	const name = 'valorant'

	const DataTasks = [
		{
			task: `Привлечь новых пользователей в сообщество VALORANT ВКонтакте`,
		},
		{
			task: `Повысить вовлеченность аудитории`,
		},
		{
			oddElement: true,
			task: `Развлечь аудиторию и повысить лояльность текущих подписчиков`,
		},
	]

	const DataResult = [
		{
			statValue: `17 900`,
			statName: `Количество привлеченных подписчиков`,
		},
		{
			statValue: `1 265 000`,
			statName: `Охват кампании`,
		},
		{
			statValue: `рост 133 %`,
			statName: `Количество уникальных посетителей в сутки`,
		},
		{
			statValue: `рост 1 420 %`,
			statName: `Переходы на сайт`,
		},
		{
			statValue: `рост 3 907 %`,
			statName: `Нажатие на кнопку действия`,
		},
		{
			statValue: `плюс 12 375 %`,
			statName: `Всего комментариев`,
		},
	]

	const SolutionText = `Команда No Sleep Agency создала и запустила геймификацию «Переверни
						игру» в официальном паблике известного тактического шутера VALORANT.
						Геймификация представляла собой адаптированного под сообщество
						чат-бота, с помощью которого каждый, кто подписан на VALORANT
						ВКонтакте, смог принять участие в битве за призы. Пользователи
						выбирали персонажей, и прокачивали их прямо в VK, выполняли
						тематические задания, взаимодействовали с интерактивными постами,
						приглашали друзей по уникальным кодам, зарабатывали баллы, чтобы
						участвовать в итоговом розыгрыше призов: геймерского монитора и
						игровых мышек.`

	return (
		<>
			<div className='wrapper'>
				<Header />

				<main>
					<HeroTemplate name={name} formatTextImage>
						<div className='hero-template__img'>
							<img src={ValorantHero} alt='Valorant' />
						</div>
						<div className='hero-template__info'>
							<h2>Геймификация ВКонтакте</h2>
							<div className='hero-template__note'>
								<h3>Для Riot Games</h3>
							</div>
							<div className='hero-template__logo-list'>
								<img src={ValorantLogos} alt='Riot Games | Valorant' />
							</div>
						</div>
					</HeroTemplate>

					<TasksTemplate name={name} dataTasks={DataTasks} />

					<SolutionTemplate
						name={name}
						solutionText={SolutionText}
						dataResult={DataResult}
					>
						<div className='solution-template-content__img-span'>
							<img src={ValorantSolution} alt='Valorant' />
						</div>
					</SolutionTemplate>
				</main>

				<Footer />
			</div>
		</>
	)
}

export default LgUltraGear
