// import { Link } from 'react-router-dom'

import './Breadcrumbs.scss'

import { AnchorLink } from '../AnchorLink'
import { IconBack } from '../../static/img/svg/IconBack'

function Breadcrumbs(props: any) {
	return (
		<>
			<div className='breadcrumbs'>
				<ul className='breadcrumbs__list'>
					{/* <li className='breadcrumbs__item'>
						<Link to='/' className={`breadcrumbs__link`}>
							<IconBack />
							<span>Главная</span>
						</Link>
					</li> */}
					<li className='breadcrumbs__item'>
						<AnchorLink className={`breadcrumbs__link`} id='cases'>
							<IconBack />
							<span>Назад</span>
						</AnchorLink>
					</li>
				</ul>
			</div>
		</>
	)
}

export { Breadcrumbs }
