import { useState } from 'react'

import './CasesSlider.scss'

import { CaseItem } from './CaseItem'
import { IconArrowRight } from '../../../static/img/svg/IconArrowRight'

import work from '../../../static/img/cases-slider/work.svg'
import nosleep from '../../../static/img/cases-slider/nosleep.svg'
import star from '../../../static/img/cases-slider/star.svg'

import xfks from '../../../static/img/cases-slider/slider/xfks.png'
import goods from '../../../static/img/cases-slider/slider/goods.png'
import lg from '../../../static/img/cases-slider/slider/lg.png'
import gigabyte from '../../../static/img/cases-slider/slider/gigabyte.png'
import faceit from '../../../static/img/cases-slider/slider/faceit.png'
import visa from '../../../static/img/cases-slider/slider/visa.png'
import sbermegasetup from '../../../static/img/cases-slider/slider/sbermegasetup.png'
import acer from '../../../static/img/cases-slider/slider/acer.png'
import valorant from '../../../static/img/cases-slider/slider/valorant.png'

import SilverMercuryAward from '../../../static/img/cases-slider/awards/silver-mercury-award.svg'
import TaglineAward from '../../../static/img/cases-slider/awards/tagline-award.svg'

function CasesSlider() {
	const [activeSlide, setActiveSlide] = useState(0)

	interface ISlideItem {
		link?: string
		img?: string
		name?: string
		title?: string
		titleSpan?: string
		colorSpan?: string
		counter?: string
		award?: string
		isActive?: boolean
	}

	interface ISlides extends Array<Array<ISlideItem>> {}

	const slides: ISlides = [
		[
			{
				link: 'sbermegasetup',
				img: sbermegasetup,
				name: 'sbermegasetup',
				title: 'ГЕЙМИФИКАЦИЯ ',
				titleSpan: '«СБЕРМЕГАСЕТАП»',
				colorSpan: '#722399',
				award: SilverMercuryAward,
			},
			{
				link: 'goods',
				img: goods,
				name: 'goods',
				title: 'Геймификация в маркетплейсе ',
				titleSpan: 'Goods.ru',
				colorSpan: '#319BD7',
				award: TaglineAward,
			},
			{
				link: 'lg-ultragear',
				img: lg,
				name: 'lg-ultragear',
				title: 'ВК розыгрыш ',
				titleSpan: 'LG UltraGear Hub',
				colorSpan: '#319BD7',
			},
		],
		[
			{
				// link: 'faceit-visa',
				img: faceit,
				name: 'faceit-visa',
				title: 'Рекламная компания ',
				titleSpan: 'Faceit, Visa',
				colorSpan: '#FF6A0B',
			},
			{
				link: 'valorant',
				img: valorant,
				name: 'valorant',
				title: 'ГЕЙМИФИКАЦИЯ ',
				titleSpan: 'ВКОНТАКТЕ',
				colorSpan: '#FF4655',
			},
			{
				link: 'acer-predator',
				img: acer,
				name: 'acer-predator',
				title: 'ТВОРЧЕСКИЙ ПРОЕКТ ',
				titleSpan: '«МАСТЕРСКАЯ ACER»',
				colorSpan: '#83B81A',
			},
		],
		[
			{
				// link: 'faceit-visa-gazprombank',
				img: visa,
				name: 'faceit-visa-gazprombank',
				title: 'Организация клиентской поддержки для держателей карты ',
				titleSpan: 'FACEIT, Visa, Газпромбанк',
				colorSpan: '#1F4AC0',
			},
			{
				link: 'xfks',
				img: xfks,
				name: 'xfks',
				title: 'No Sleep Agency ',
				titleSpan: 'x ФКС',
				colorSpan: '#319BD7',
			},

			{
				// link: 'gigabyte',
				img: gigabyte,
				name: 'gigabyte',
				title: 'Портал участников соц. сетей ',
				titleSpan: 'Gygabyte',
				colorSpan: '#FF6A0B',
			},
		],
	]

	return (
		<>
			<section id='cases' className='cases'>
				<div className='container'>
					<div className='cases__title-wrp'>
						<img className='cases__title-work' src={work} alt='work' />
						<h2 className='cases__title'>
							НАШИ <span className='elipses'>КЕЙСЫ</span>
						</h2>
					</div>
					<div className='cases-slider'>
						<div className='swiper-wrapper' aria-live='polite'>
							{slides.map((slide, index) => (
								<div
									key={index}
									className={`cases-slider__slide swiper-slide ${
										activeSlide === index ? 'swiper-slide-active' : ''
									}`}
								>
									{slide.map((item, itemIndex) => {
										const counter = String(index * slide.length + itemIndex + 1)
										return (
											<CaseItem
												key={itemIndex}
												index={itemIndex}
												link={item.link}
												img={item.img}
												name={item.name}
												title={item.title}
												titleSpan={item.titleSpan}
												colorSpan={item.colorSpan}
												counter={counter}
												award={item.award}
												isActive={activeSlide === index}
											/>
										)
									})}
								</div>
							))}
						</div>
						<div className='cases-slider__controlls'>
							<div className='swiper-pagination'>
								<span className='swiper-pagination-current'>
									{activeSlide + 1}
								</span>
								{` / `}
								<span className='swiper-pagination-total'>{slides.length}</span>
							</div>
							<div className='sl-nav'>
								<div
									className={`sl-nav__btn sl-nav__btn--prev ${
										activeSlide === 0 ? 'swiper-button-disabled' : ''
									}`}
									onClick={() => setActiveSlide(activeSlide - 1)}
								>
									<IconArrowRight />
								</div>
								<div
									className={`sl-nav__btn sl-nav__btn--next ${
										activeSlide === slides.length - 1
											? 'swiper-button-disabled'
											: ''
									}`}
									onClick={() => setActiveSlide(activeSlide + 1)}
								>
									<IconArrowRight />
								</div>
							</div>
						</div>
						<div className='cases-slider__decor'>
							<div className='cases-slider__decor-top'>
								<img src={nosleep} alt='nosleep' />
								<div className='cases-slider__decor-circles'>
									<span></span>
									<span></span>
									<span></span>
									<span></span>
									<span></span>
									<span></span>
								</div>
							</div>
							<div className='cases-slider__decor-bottom'>
								<img src={star} alt='star' />
								<p>{`AGE \nNCY`}</p>
							</div>
						</div>
						<span
							className='swiper-notification'
							aria-live='assertive'
							aria-atomic='true'
						></span>
					</div>
				</div>
			</section>
		</>
	)
}

export { CasesSlider }
