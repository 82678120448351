import './AboutAs.scss'

import NoTitle from '../../../static/img/about-us/title/no.svg'
import SleepTitle from '../../../static/img/about-us/title/sleep.svg'
import AgencyTitle from '../../../static/img/about-us/title/agency.svg'
import StarTitle from '../../../static/img/about-us/title/star.svg'

import Nono from '../../../static/img/about-us/nono.svg'
import Sleep from '../../../static/img/about-us/sleep.svg'

import DecorMin from '../../../static/img/about-us/decor-min.png'

function AboutAs() {
	return (
		<>
			<section className='about-us'>
				<div className='about-us__decor'>
					<img src={DecorMin} alt='decor-min' />
				</div>
				<div className='about-us__decor-2'>
					<img src={DecorMin} alt='decor-min' />
				</div>
				<div className='container'>
					<div className='about-us__img'>
						<div className='about-us__img-img'>
							<img className='about-us__img-ino' src={NoTitle} alt='no' />
							<img
								className='about-us__img-ileep'
								src={SleepTitle}
								alt='sleep'
							/>
							<img
								className='about-us__img-agency'
								src={AgencyTitle}
								alt='agency'
							/>
							<img className='about-us__img-star1' src={StarTitle} alt='star' />
							<img className='about-us__img-star2' src={StarTitle} alt='star' />
						</div>
						<img className='about-us__img-no' src={Nono} alt='nono' />
						<img className='about-us__img-sleep' src={Sleep} alt='sleep' />
					</div>
					<div className='about-us-grid'>
						<div className='about-us-grid__item'>
							<span className='about-us-grid__item-title'>О нас</span>
							<p className='about-us-grid__item-txt'>
								No Sleep Agency — молодое digital-агентство, энергетика которого
								воплощает яркие и нестандартные идеи в проекты
							</p>
						</div>
						<div className='about-us-grid__item'>
							<span className='about-us-grid__item-title'>НАША МИССИЯ</span>
							<p className='about-us-grid__item-txt'>
								Наша миссия — расширить рамки возможного для клиента. Мы верим,
								что индивидуальный подход, креатив и эффективная работа
								наполняют и заряжают любой проект на все 100%.
							</p>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export { AboutAs }
