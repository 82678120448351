function IconArrowLong() {
	return (
		<>
			<svg
				width='35'
				height='16'
				viewBox='0 0 35 16'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M34.7071 8.70711C35.0976 8.31658 35.0976 7.68342 34.7071 7.29289L28.3431 0.928932C27.9526 0.538408 27.3195 0.538408 26.9289 0.928932C26.5384 1.31946 26.5384 1.95262 26.9289 2.34315L32.5858 8L26.9289 13.6569C26.5384 14.0474 26.5384 14.6805 26.9289 15.0711C27.3195 15.4616 27.9526 15.4616 28.3431 15.0711L34.7071 8.70711ZM-6.76987e-10 9L34 9L34 7L6.76987e-10 7L-6.76987e-10 9Z'
					fill='current'
				/>
			</svg>
		</>
	)
}

export { IconArrowLong }
