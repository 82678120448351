import { useState } from 'react'
import { useForm } from 'react-hook-form'
// import axios from 'axios'

import styles from './ContactsBlock.module.scss'

import { Button } from '../../Buttons/Button'
import { FormInput } from '../../FormFields/FormInput'
import { FormTextarea } from '../../FormFields/FormTextarea'

import ContactsDec from '../../../static/img/hero/dec1-min.png'

import { IconDocument } from '../../../static/img/svg/IconDocument'

function ContactsBlock() {
	const sendFileToTg = async (document: File) => {
		console.log(document)
		console.log(typeof document)

		const request = new XMLHttpRequest()
		const formData = new FormData()

		request.open(
			'POST',
			'https://api.telegram.org/bot6130887297:AAGWZQTCouVSf12qSm0QMvOMIcDPEHMZn34/sendDocument',
			true
		)

		request.onreadystatechange = () => {
			if (request.readyState === 4 && request.status === 200) {
				console.log(request.responseText)
			}
		}

		formData.append('document', document)
		formData.append('chat_id', '-623121348')
		request.send(formData)
	}

	const sendToTg = async (txt: string) => {
		await fetch(
			`https://api.telegram.org/bot6130887297:AAGWZQTCouVSf12qSm0QMvOMIcDPEHMZn34/sendMessage?chat_id=-623121348&parse_mode=html&text=${txt}`
		)
	}

	const [sent, setSent] = useState(false)
	const [inputValues, setInputValues] = useState({
		name: '',
		company: '',
		email: '',
		phone: '',
		task: '',
		comment: '',
		file: null,
	})

	const handleOnChange = (event: any) => {
		const { name, value } = event.target
		console.log(name)
		console.log(value)
		setInputValues({ ...inputValues, [name]: value })
	}

	const sendForm = async () => {
		if (sent) return
		setSent(true)

		try {
			await sendToTg(
				`<b>Новая завяка от ${
					inputValues.name ? inputValues.name : 'Инкогнито'
				} (${
					inputValues.company ? inputValues.company : 'компания неизвестна'
				})</b>%0A` +
					`<b>Телефон: ${
						inputValues.phone ? inputValues.phone : 'отсутствует'
					}</b>%0A` +
					`<b>Email: ${
						inputValues.email ? inputValues.email : 'отсутствует'
					}</b>%0A` +
					`<b>Задачи: ${
						inputValues.task ? inputValues.task : 'отсутствуют'
					}</b>%0A` +
					`<b>Комментарий: ${
						inputValues.comment ? inputValues.comment : 'отсутствует'
					}</b>`
			)

			if (inputValues.file) {
				await sendFileToTg(inputValues.file)
			}

			alert('Успешно отправлено')
		} catch (e) {
			console.log(e)
		}
	}

	const [fileName, setFileName] = useState<string | null>(null)

	const fileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const file: File = (event.target.files as FileList)[0]
		setFileName(file.name)

		// @ts-ignore
		setInputValues({ ...inputValues, file })
	}

	// validation

	const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

	const {
		register,
		formState: { errors, isValid },
		handleSubmit,
		reset,
	} = useForm({ mode: 'onBlur' })

	const onSubmit = () => {
		reset()
	}

	return (
		<>
			<section className={styles.root}>
				<div className={styles.decoration}>
					<img
						className={styles.decorationImg}
						src={ContactsDec}
						alt='ContactsDec'
					/>
					<img
						className={styles.decorationImg2}
						src={ContactsDec}
						alt='ContactsDec'
					/>
				</div>
				<div className={`container ${styles.container}`}>
					<div className={styles.header}>
						<h2>Отправьте нам бриф</h2>
						<h5 className={styles.note}>
							(*) — обязательные поля для заполнения
						</h5>
					</div>

					<form onSubmit={handleSubmit(onSubmit)} className='form-feedback'>
						<div className={styles.form}>
							<div className={styles.formRow}>
								<FormInput
									placeholder='* Ваше имя'
									type='text'
									value={inputValues.name}
									register={register('name', {
										required: 'Напишите ваше имя',
										onChange: e => handleOnChange(e),
									})}
									onChange={handleOnChange}
									error={errors?.name}
								/>
								<FormInput
									placeholder='* Компания'
									type='text'
									value={inputValues.company}
									register={register('company', {
										required: 'Напишите название вашей компании',
										onChange: e => handleOnChange(e),
									})}
									error={errors?.company}
								/>
							</div>

							<div className={styles.formRow}>
								<FormInput
									placeholder='* E-mail'
									type='email'
									value={inputValues.email}
									register={register('email', {
										required: 'Напишите ваш e-mail',
										onChange: e => handleOnChange(e),
										pattern: {
											value: emailPattern,
											message: 'Введите корректный email',
										},
									})}
									error={errors?.email}
								/>
								<FormInput
									placeholder='Телефон'
									type='tel'
									name='phone'
									value={inputValues.phone}
									onChange={handleOnChange}
								/>
							</div>

							<FormTextarea
								placeholder='* Задачи рекламной кампании'
								id='task'
								value={inputValues.task}
								register={register('task', {
									required: 'Напишите задачи рекламной кампании',
									onChange: e => handleOnChange(e),
								})}
								error={errors?.task}
							/>
							<FormTextarea
								placeholder='Комментарий'
								name='comment'
								id='comment'
								value={inputValues.comment}
								onChange={handleOnChange}
							/>

							<div className={styles.footer}>
								<Button
									type='submit'
									value='Отправить'
									onClick={sendForm}
									disabled={!isValid}
								/>

								<div className={styles.fileUpload}>
									<Button
										type={`file`}
										value='Прикрепить файл'
										onChange={fileUpload}
										black
									>
										<IconDocument />
									</Button>
									<div className={styles.fileName}>
										{fileName ? (
											<>
												<span>
													<h6>Имя файла:</h6>
												</span>
												<h6>{fileName}</h6>
											</>
										) : (
											<span>
												<h6>Файл не выбран</h6>
											</span>
										)}
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</section>
		</>
	)
}

export { ContactsBlock }
