import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import Home from '../pages/Home'
import Contacts from '../pages/Contacts'

import Goods from '../pages/cases/Goods'
import Xfks from '../pages/cases/Xfks'
import LgUltraGear from '../pages/cases/LgUltraGear'
import SberMegaSetup from '../pages/cases/SberMegaSetup'
import AcerPredator from '../pages/cases/AcerPredator'
import Valorant from '../pages/cases/Valorant'
import FaceitVisa from '../pages/cases/FaceitVisa'
import FaceitVisaGazprombank from '../pages/cases/FaceitVisaGazprombank'
import Gigabyte from '../pages/cases/Gigabyte'

import NotFound from '../pages/NotFound'

export const AppRouter: React.FC = () => {
	return (
		<Routes>
			<Route path='/' element={<Home />} />
			<Route path='/contacts' element={<Contacts />} />

			<Route path='/goods' element={<Goods />} />
			<Route path='/xfks' element={<Xfks />} />
			<Route path='/lg-ultragear' element={<LgUltraGear />} />
			<Route path='/sbermegasetup' element={<SberMegaSetup />} />
			<Route path='/valorant' element={<Valorant />} />
			<Route path='/acer-predator' element={<AcerPredator />} />
			<Route path='/faceit-visa' element={<FaceitVisa />} />
			<Route path='/gigabyte' element={<FaceitVisaGazprombank />} />
			<Route path='/faceit-visa-gazprombank' element={<Gigabyte />} />

			<Route path='/404' element={<NotFound />} />
			<Route path='*' element={<Navigate to='/' replace />} />
		</Routes>
	)
}
