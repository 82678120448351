import { useEffect } from 'react'

import { Header } from '../../components/Header'
import { HeroTemplate } from '../../components/ContentBlocks/templates/HeroTemplate'
import { TasksTemplate } from '../../components/ContentBlocks/templates/TasksTemplate'
import { SolutionTemplate } from '../../components/ContentBlocks/templates/SolutionTemplate'
import { Footer } from '../../components/Footer'

import SberMegaSetupHero from '../../static/img/cases/sbermegasetup/sbermegasetup-hero.png'
import SberMegaSetupLogo from '../../static/img/cases/sbermegasetup/sbermegasetup-logo.svg'
import SberMMLogo from '../../static/img/cases/sbermegasetup/sbermm-logo.svg'
import SberMSSolutionPng from '../../static/img/cases/sbermegasetup/sbermegasetup-solution.png'
import SberMSSolutionGif from '../../static/img/cases/sbermegasetup/sbermegasetup-solution.gif'
import SberMSSolutionDecor from '../../static/img/cases/sbermegasetup/sbermegasetup-solution-decor.svg'
import SberMSTasksDecor from '../../static/img/cases/sbermegasetup/sbermegasetup-tasks-decor.svg'

function SberMegaSetup() {
	useEffect(() => {
		document.title = 'No Sleep Agency — «СберМегаСетап»'
		window.scrollTo(0, 0)
	}, [])

	const name = 'sbermegasetup'

	const DataTasks = [
		{
			task: `Развлечь аудиторию сообщества и поблагодарить подписчиков за участие в жизни сообщества`,
		},
		{
			task: `Увеличить количество подписчиков в группе`,
		},
		{
			task: `Увеличить количество взаимодействий пользователей с сайтом СберМегаМаркета`,
		},
		{
			task: `Рассказать о брендах-партнерах СберМегаМаркета, подсветить определенные подборки товаров партнеров на маркетплейсе`,
		},
	]

	const DataResult = [
		{
			statValue: `9 124 433`,
			statName: `Общее количество просмотров лендинга`,
		},
		{
			statValue: `11 236 980`,
			statName: `Общий охват всех постов геймификации`,
		},
		{
			statValue: `2 787 060`,
			statName: `Уникальный охват геймификации`,
		},
		{
			statValue: `22 400`,
			statName: `Новые подписчики в группе плюс`,
		},
		{
			statValue: `17 348`,
			statName: `Участники геймификации`,
		},
	]

	const SolutionText = `Пользователи набирали баллы за активность в сообществе и прокачивали
						уровень своей геймерской комнаты. В процессе прокачки комнаты,
						пользователи могли выбрать, какие девайсы партнера они хотят видеть у
						себя в комнате. В зависимости от этого менялся их внешний вид — стол,
						кресло, монитор, клавиатура и так далее. В итоге у нас получилось 6
						276 возможных вариантов сетапа. Задействовано множество вовлекающих
						механик в проекте. Набрать баллы пользователь мог с помощью нескольких
						механик: викторины, битвы с боссом, мини-игры, дуэли, приглашение
						друзей, а также за лайки и комментарии в сообществе SberMegaMarket
						Hub.`

	return (
		<>
			<div className='wrapper'>
				<Header />

				<main>
					<HeroTemplate name={name} formatTextImage>
						<div className='hero-template__img'>
							<img src={SberMegaSetupHero} alt='hero' />
						</div>
						<div className='hero-template__info'>
							<h2>Геймификация «СберМегаСетап»</h2>
							<div className='hero-template__note'>
								<h3>Для СберМегаМаркет</h3>
							</div>
							<div className='hero-template__logo-list'>
								<img src={SberMMLogo} alt='СберМегаМаркет' />
								<img src={SberMegaSetupLogo} alt='СберМегаСетап' />
							</div>
						</div>
					</HeroTemplate>

					<TasksTemplate
						name={name}
						dataTasks={DataTasks}
						decor={
							<>
								<div className='tasks-template__decor-span'>
									<img src={SberMSTasksDecor} alt='SberMSTasksDecor' />
								</div>
								<div className='tasks-template__decor-span1'>
									<img src={SberMSSolutionDecor} alt='SberMSSolutionDecor' />
								</div>
							</>
						}
					/>

					<SolutionTemplate
						name={name}
						solutionText={SolutionText}
						dataResult={DataResult}
					>
						<div className='solution-template-content__img-span'>
							<img src={SberMSSolutionPng} alt='SberMSSolutionPng' />
						</div>
						<div className='solution-template-content__img-gif'>
							<img src={SberMSSolutionGif} alt='SberMSSolutionGif' />
						</div>
					</SolutionTemplate>
				</main>

				<Footer />
			</div>
		</>
	)
}

export default SberMegaSetup
