import './Solution2.scss'

import Game from '../../../static/img/solution2/game-game.svg'
import BgDecMin from '../../../static/img/solution2/bg-dec-min.png'
import GodsMin from '../../../static/img/solution2/g-o-ds-min.png'
import Weapon from '../../../static/img/solution2/weapon-min.png'
import PhotoMin from '../../../static/img/solution2/photo-min.png'

function Solution2() {
	return (
		<>
			<section className='solution2'>
				<div className='solution2__game'>
					<img src={Game} alt='game-game' />
				</div>
				<div className='solution2__decor'>
					<img src={BgDecMin} alt='bg-dec-min' />
				</div>
				<div className='solution2__goods'>
					<img src={GodsMin} alt='g-o-ds-min' />
				</div>
				<div className='solution2__weapon'>
					<img src={Weapon} alt='weapon-min' />
				</div>

				<div className='container'>
					<h2 className='solution2__title'>Решение</h2>
					<div className='solution2-content'>
						<div className='solution2-content__img'>
							<div className='solution2-content__img-span'>
								<img src={PhotoMin} alt='min' />
							</div>
						</div>
						<div className='solution2-content__txt'>
							<p className='solution2-content__txt-p'>
								Для геймеров и фанатов киберспорта нет лучшего триггера, чем
								игра, поэтому мы запустили геймификацию игрового раздела goods
								game. Помогал нам в этом целевой трафик, группа ВКонтакте и
								Арсений Триноженко — многократный чемпион мира по Counter
								Strike, которого goods.ru привлек в качестве амбассадора. Мы
								разработали 4-недельный проект, включавший в себя элементы
								геймификации. Еженедельно на сайте открывались новые задания, за
								прохождение которых пользователь получал цифровые призы — скины
								из видеоигр Counter Strike и Dota2, стоимостью от 3 до 1 000
								рублей.
							</p>
							<h4 className='solution2-content__txt-title'>
								Результаты <span></span>
							</h4>
							<div className='solution2-content__txt-stat'>
								<p className='solution2-content__txt-stat-p'>
									<span>12 000 000</span> Охвата целевой аудитории
								</p>
								<p className='solution2-content__txt-stat-p'>
									<span>150 000</span> Уникальных посетителей лендинга
								</p>
								<p className='solution2-content__txt-stat-p'>
									<span>20 000</span> Зарегистрированных пользователей
								</p>
								<p className='solution2-content__txt-stat-p'>
									<span>&gt; 70 000</span> Подписчиков в сообщества ВКонтакте
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export { Solution2 }
