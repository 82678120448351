import MaskedInput from 'react-text-mask'

import styles from './FormInput.module.scss'

interface FormInputProps {
	placeholder?: string
	type?: string
	name?: string
	onChange?: React.ChangeEventHandler<HTMLInputElement>
	onClick?: React.MouseEventHandler<HTMLInputElement>
	register?: any
	id?: string
	error?: any
	value?: any
}

const phoneMask = [
	'+',
	'7',
	' ',
	'(',
	/\d/,
	/\d/,
	/\d/,
	')',
	' ',
	/\d/,
	/\d/,
	/\d/,
	' ',
	/\d/,
	/\d/,
	'-',
	/\d/,
	/\d/,
]

const FormInput: React.FC<FormInputProps> = ({
	placeholder,
	type,
	name,
	onChange,
	onClick,
	register,
	id,
	error,
}) => (
	<>
		<div className={styles.root}>
			{type === 'tel' ? (
				<MaskedInput
					mask={phoneMask}
					className={styles.input}
					placeholder={placeholder}
					type={type}
					name={name}
					onChange={onChange}
					onClick={onClick}
					// guide={false}
					id={id}
					{...register}
				/>
			) : (
				<input
					className={`${styles.input} ${error ? styles.error : ''}`}
					placeholder={placeholder}
					type={type}
					name={name}
					onChange={onChange}
					onClick={onClick}
					{...register}
				/>
			)}

			{error && (
				<h6 className={styles.errorValue}>
					{error?.message || 'Заполните это поле'}
				</h6>
			)}
		</div>
	</>
)

export { FormInput }
