import styles from './Button.module.scss'

// import { Link } from 'react-router-dom'

interface ButtonProps {
	type?: any
	value?: string
	name?: string
	onChange?: any
	onClick?: any
	children?: any
	black?: boolean
	disabled?: boolean
}

const Button: React.FC<ButtonProps> = ({
	type,
	value,
	children,
	onChange,
	onClick,
	black,
	disabled,
}) => (
	<>
		{type === 'file' ? (
			<label
				className={`${styles.root} ${styles.fileUpload} ${
					black ? styles.black : styles.red
				}`}
				onClick={onClick}
				onChange={onChange}
			>
				<input type='file' id='file-upload' />

				{children}
				{value ? <h5>{value}</h5> : <h5>Button</h5>}
			</label>
		) : (
			<button
				type={type}
				className={`${styles.root} ${black ? styles.black : styles.red} `}
				onClick={onClick}
				onChange={onChange}
				disabled={disabled}
			>
				{children}
				{value ? <h5>{value}</h5> : <h5>Button</h5>}
			</button>
		)}
	</>
)

export { Button }
