import { Link } from 'react-router-dom'

import './Examples.scss'

import Camera from '../../../static/img/examples/camera-min.png'
import Txt from '../../../static/img/examples/txt.svg'
import Star from '../../../static/img/cases-slider/star.svg'

function Examples() {
	return (
		<>
			<section className='examples'>
				<div className='container'>
					<div className='examples-content'>
						<div className='examples-content__item examples-info'>
							<div className='examples-info__title'>
								Примеры <span>контента</span>
								<span className='examples-info__title-border'></span>
							</div>
							<div className='examples-info__links'>
								<Link
									className='link'
									target='_blank'
									to='https://www.tiktok.com/@prepodsteam/video/7022692926471933186'
								>
									www.tiktok.com/@prepodsteam/video
								</Link>
								<Link
									className='link'
									target='_blank'
									to='https://www.tiktok.com/@gaymercsgo/video/7022983302441159938'
								>
									www.tiktok.com/@gaymercsgo/video
								</Link>
								<Link
									className='link'
									target='_blank'
									to='https://www.tiktok.com/@llluiko/video/7023730540356324610'
								>
									www.tiktok.com/@llluiko/video
								</Link>
								<Link
									className='link'
									target='_blank'
									to='https://www.tiktok.com/@csgotrend/video/7022232004666068225'
								>
									www.tiktok.com/@csgotrend/video
								</Link>
							</div>
						</div>
						<div className='examples-content__item examples-img'>
							<div className='examples-img__camera'>
								<img src={Camera} alt='camera-min' />
							</div>
							<div className='examples-img__circles'>
								<span></span>
								<span></span>
								<span></span>
								<span></span>
								<span></span>
								<span></span>
							</div>
							<div className='examples-img__txt'>
								<img src={Txt} alt='txt' />
							</div>
							<div className='examples-img__star'>
								<img src={Star} alt='star' />
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export { Examples }
