import { useEffect } from 'react'

import { Header } from '../../components/Header'
import { HeroTemplate } from '../../components/ContentBlocks/templates/HeroTemplate'
import { TasksTemplate } from '../../components/ContentBlocks/templates/TasksTemplate'
import { SolutionTemplate } from '../../components/ContentBlocks/templates/SolutionTemplate'
import { Footer } from '../../components/Footer'

import MonitorHero from '../../static/img/cases/lg-ultragear/monitor-hero.png'
import LgHero from '../../static/img/cases/lg-ultragear/lg-hero.svg'
import LgUltraGearHub from '../../static/img/cases/lg-ultragear/lg-ultragear-logo.svg'
import LgUltraGearHubHorizontal from '../../static/img/cases/lg-ultragear/lg-ultragear-logo_horizontal.svg'
import Letw1k3 from '../../static/img/cases/lg-ultragear/solution/letw1k3.png'
import FortniteCharacters from '../../static/img/cases/lg-ultragear/solution/fortnite-characters.png'
import FortniteText from '../../static/img/cases/lg-ultragear/solution/fortnite-text.png'
import TrianglesOrange from '../../static/img/cases/lg-ultragear/solution/triangles-orange.svg'
import TrianglesRed from '../../static/img/cases/lg-ultragear/solution/triangles-red.svg'

function LgUltraGear() {
	useEffect(() => {
		document.title = 'No Sleep Agency — LG UltraGear Hub'
		window.scrollTo(0, 0)
	}, [])

	const name = 'lg-ultragear'

	const DataTasks = [
		{
			task: `Охватить аудиторию киберспорта и гейминга`,
		},
		{
			task: `Нарастить клиентскую базу`,
		},
		{
			task: `Привлечь подписчиков в новое тематическое сообщество ВКонтакте`,
		},
		{
			task: `Помочь конвертировать привлеченных пользователей в клиентов`,
		},
	]

	const DataResult = [
		{
			statValue: `3 515 998`,
			statName: `Охвата целевой аудитории`,
		},
		{
			statValue: `646 422`,
			statName: `Комментариев`,
		},
		{
			statValue: `18 760`,
			statName: `Подписчиков `,
		},
		{
			statValue: `30%`,
			statName: `Увеличение ER на`,
		},
	]

	const SolutionText = `Мы разработали чат-бота и создали игру, основываясь на образе
						амбассадора — киберспортсмена в дисциплине Fortnite Марка
						Letw1k3 Данилова. Были выполнены задачи по созданию дизайна,
						механики и разработке контента для сообщества, связанного со
						спецпроектом.\nПользователи набирали баллы за активность в
						сообществе UltraGear HUB (лайки, комментарии) — вся игра была
						оформлена в стиле классической механики прокачки персонажа в
						играх: каждый участник получал своего аватара, уровень которого
						повышался, когда достигалось нужное количество баллов. После
						взятия каждого уровня, внешний вид персонажа менялся на более
						эффектный. Еженедельно самые активные участники премировались
						внутриигровыми предметами для Fortnite, а по достижении
						максимального уровня, пользователь получал возможность
						поучаствовать в розыгрыше с более крупными призами.`

	return (
		<>
			<div className='wrapper'>
				<Header />

				<main>
					<HeroTemplate name={name}>
						<div className='hero-template__left'>
							<img src={LgHero} alt='LG' />
						</div>
						<div className='hero-template__middle'>
							<div className='hero-template__middle-logo'>
								<img src={LgUltraGearHubHorizontal} alt='LG UltraGear Hub' />
							</div>
							<div className='hero-template__middle-monitor'>
								<img src={MonitorHero} alt='Монитор' />
							</div>
							<h2>ВК РОЗЫГРЫШ</h2>
						</div>
						<div className='hero-template__right'>
							<img src={LgUltraGearHub} alt='LG UltraGear Hub' />
						</div>
					</HeroTemplate>

					<TasksTemplate
						name={name}
						dataTasks={DataTasks}
						decor={
							<>
								<div className='tasks-template__decor-span1'>
									<img src={TrianglesOrange} alt='TrianglesOrange' />
								</div>

								<div className='tasks-template__decor-span2'>
									<img src={TrianglesRed} alt='TrianglesRed' />
								</div>
							</>
						}
					/>

					<SolutionTemplate
						name={name}
						solutionText={SolutionText}
						dataResult={DataResult}
						decor={
							<>
								<div className='solution-template-content__decor-span1'>
									<img src={FortniteCharacters} alt='Fortnite' />
								</div>

								<div className='solution-template-content__decor-span2'>
									<img src={FortniteText} alt='Fortnite' />
								</div>
							</>
						}
					>
						<div className='solution-template-content__img-span'>
							<img src={Letw1k3} alt='Letw1k3' />
						</div>
					</SolutionTemplate>
				</main>

				<Footer />
			</div>
		</>
	)
}

export default LgUltraGear
