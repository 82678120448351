import { useEffect } from 'react'

import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { ContactsPage } from '../components/ContentBlocks/ContactsPage'

function Contacts() {
	useEffect(() => {
		document.title = 'No Sleep Agency — Контакты'
		window.scrollTo(0, 0)
	}, [])

	return (
		<>
			<div className='wrapper'>
				<Header />

				<main>
					<ContactsPage />
				</main>

				<Footer />
			</div>
		</>
	)
}

export default Contacts
