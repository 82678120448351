import { useEffect } from 'react'

import { Header } from '../../components/Header'
import { HeroTemplate } from '../../components/ContentBlocks/templates/HeroTemplate'
import { TasksTemplate } from '../../components/ContentBlocks/templates/TasksTemplate'
import { SolutionTemplate } from '../../components/ContentBlocks/templates/SolutionTemplate'
import { Footer } from '../../components/Footer'

import AcerPredatorHero from '../../static/img/cases/acer-predator/acer-predator-hero.png'
import AcerPredatorLogos from '../../static/img/cases/acer-predator/acer-predator-logos.svg'
import AcerPredatorSolution from '../../static/img/cases/acer-predator/acer-predator-solution.png'

function AcerPredator() {
	useEffect(() => {
		document.title = 'No Sleep Agency — «Мастерская Acer»'
		window.scrollTo(0, 0)
	}, [])

	const name = 'acer-predator'

	const DataTasks = [
		{
			task: `Сфокусировать внимание на ноутбуках Predator и ConceptD`,
		},
		{
			task: `Вовлечь аудиторию во взаимодействие с брендами`,
		},
		{
			oddElement: true,
			task: `Вдохновить и развлечь привлекаемую аудиторию`,
		},
	]

	const DataResult = [
		{
			statValue: `4 620 786`,
			statName: `Общий охват анонсирования проекта`,
		},
		{
			statValue: `43 555`,
			statName: `Уникальных пользователей на лендинге`,
		},
		{
			statValue: `11 966 198`,
			statName: `Общее количество просмотров`,
		},
		{
			statValue: `101 171`,
			statName: `Количество просмотров лендинга`,
		},
	]

	const SolutionText = `Мы запустили творческий спецпроект совместно с площадкой Stopgame.ru,
						направленный на привлечение двух разных сегментов аудитории - геймеров
						и художников, дизайнеров. Для участия в творческом конкурсе необходимо
						было придумать и нарисовать игровой скин/digital art, который будет
						стильно и красиво смотреться на крышке ноутбука Predator. А
						пользователям, которые хотели поучаствовать в розыгрыше ноутбука из
						этой линейки, нужно было авторизоваться на странице проекта,
						проголосовать за работы художников и поделиться понравившейся работой
						в социальных сетях.`

	return (
		<>
			<div className='wrapper'>
				<Header />

				<main>
					<HeroTemplate name={name} formatTextImage>
						<div className='hero-template__img'>
							<img src={AcerPredatorHero} alt='«Мастерская Acer»' />
						</div>
						<div className='hero-template__info'>
							<h2>{`Творческий\nпроект`}</h2>
							<div className='hero-template__note'>
								<h3>«Мастерская Acer»</h3>
							</div>
							<div className='hero-template__logo-list'>
								<img src={AcerPredatorLogos} alt='Acer | Predator' />
							</div>
						</div>
					</HeroTemplate>

					<TasksTemplate name={name} dataTasks={DataTasks} />

					<SolutionTemplate
						name={name}
						solutionText={SolutionText}
						dataResult={DataResult}
					>
						<div className='solution-template-content__img-span'>
							<img src={AcerPredatorSolution} alt='Acer | Predator' />
						</div>
					</SolutionTemplate>
				</main>

				<Footer />
			</div>
		</>
	)
}

export default AcerPredator
