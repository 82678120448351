import { BrowserRouter } from 'react-router-dom'

import { AppRouter } from './components/AppRouter'
import { PagePreloader } from './components/PagePreloader'

import './styles/index.scss'

function App() {
	return (
		<BrowserRouter>
			{/* <PagePreloader /> */}
			<AppRouter />
		</BrowserRouter>
	)
}

export default App
