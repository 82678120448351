import { useEffect } from 'react'

import { Header } from '../../components/Header'
import { Footer } from '../../components/Footer'
import { HeroFks } from '../../components/ContentBlocks/HeroFks'
import { Solution } from '../../components/ContentBlocks/Solution'
import { Examples } from '../../components/ContentBlocks/Examples'
import { Results } from '../../components/ContentBlocks/Results'

function Xfks() {
	useEffect(() => {
		document.title = 'No Sleep Agency — ФКС'
		window.scrollTo(0, 0)
	}, [])

	return (
		<>
			<div className='wrapper'>
				<Header />

				<main>
					<HeroFks />
					<Solution />
					<Examples />
					<Results />
				</main>

				<Footer />
			</div>
		</>
	)
}

export default Xfks
