import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { scroller } from 'react-scroll'

import styles from './AnchorLink.module.scss'

function AnchorLink(props: any) {
	const navigate = useNavigate()
	const location = useLocation()

	function handleClick(id: any) {
		navigate(`/?id=${id}`)
	}

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search)
		const id = searchParams.get('id')

		if (id) {
			scroller.scrollTo(id, {
				smooth: true,
				offset: -120,
			})
		}
	}, [location.search])

	return (
		<>
			<div
				className={`${styles.root} ${props.className ? props.className : ''}`}
				onClick={() => handleClick(props.id)}
			>
				{props.children}
			</div>
		</>
	)
}

export { AnchorLink }
