import { useState } from 'react'
import { Link } from 'react-router-dom'

import styles from './Header.module.scss'
import useDocumentScrollThrottled from './index'

import { AnchorLink } from '../AnchorLink'
import { BurgerNavigation } from '../BurgerNavigation'

import Logo from '../../static/img/header/logo.svg'

function Header() {
	// header-scroll
	const [shouldHideHeader, setShouldHideHeader] = useState(false)
	const [currentScrollTop, setCurrentScrollTop] = useState(0)

	const MINIMUM_SCROLL = 1

	useDocumentScrollThrottled((callbackData: { currentScrollTop: number }) => {
		setCurrentScrollTop(callbackData.currentScrollTop)
		setShouldHideHeader(callbackData.currentScrollTop > 0)
	})

	const hiddenStyle =
		shouldHideHeader && currentScrollTop > MINIMUM_SCROLL ? styles.scroll : ''

	// burger
	const [burgerActive, setBurgerActive] = useState(false)

	const mobileMenu = () => {
		setBurgerActive(!burgerActive)
		document.body.classList.toggle('no-scroll')
	}

	return (
		<>
			<header className={`${styles.root} ${hiddenStyle}`}>
				<div className='container'>
					<div className={styles.inner}>
						<ul className={`header-nav ${styles.navigation} ${styles.left}`}>
							<li className={styles.navigationItem}>
								<AnchorLink className={styles.navigationLink} id='cases'>
									Кейсы
								</AnchorLink>
							</li>
							<li className={styles.navigationItem}>
								<Link to='/contacts' className={styles.navigationLink}>
									Контакты
								</Link>
							</li>
						</ul>

						<Link to='/' className={`logo ${styles.logo}`}>
							<img src={Logo} alt='No Sleep Agency' />
						</Link>

						<ul className={`header-nav ${styles.navigation} ${styles.right}`}>
							<li className={styles.navigationItem}>
								<Link to='/' className={styles.navigationLink}>
									Услуги
								</Link>
							</li>
							<li className={styles.navigationItem}>
								<Link to='/' className={styles.navigationLink}>
									Разработки
								</Link>
							</li>
						</ul>

						<div
							onClick={mobileMenu}
							className={`${styles.burger} ${
								burgerActive ? styles.burgerIsActive : ''
							}`}
						>
							<div className={styles.burgerItem}></div>
							<div className={styles.burgerItem}></div>
							<div className={styles.burgerItem}></div>
							<div className={styles.burgerItem}></div>
						</div>

						<BurgerNavigation
							mobileMenu={mobileMenu}
							burgerActive={burgerActive}
						/>
					</div>
				</div>
			</header>
		</>
	)
}

export { Header }
