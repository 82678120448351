import { Link } from 'react-router-dom'

import './CompaniesTrust.scss'

import Acer from '../../../static/img/company-logos/acer.svg'
import Aorus from '../../../static/img/company-logos/aorus.svg'
import Faceit from '../../../static/img/company-logos/faceit.svg'
import Gazprombank from '../../../static/img/company-logos/gazprombank.svg'
import Gigabyte from '../../../static/img/company-logos/gigabyte.svg'
import GoodsRu from '../../../static/img/company-logos/goods-ru.svg'
import LgElectronics from '../../../static/img/company-logos/lg-electronics.svg'
import MTS from '../../../static/img/company-logos/mts.svg'
import Redragon from '../../../static/img/company-logos/redragon.svg'
import RiotGames from '../../../static/img/company-logos/riot-games.svg'
import Sbermm from '../../../static/img/company-logos/sbermm.svg'
import Visa from '../../../static/img/company-logos/visa.svg'
import WasdTV from '../../../static/img/company-logos/wasd-tv.svg'
import Xiaomi from '../../../static/img/company-logos/xiaomi.svg'

import Line1 from '../../../static/img/companies-trust/line-1.svg'
import Line2 from '../../../static/img/companies-trust/line-2.svg'

import Circle from '../../../static/img/companies-trust/circle.svg'

function CompaniesTrust() {
	return (
		<>
			<section className='companies-trust'>
				<div className='container'>
					<div className='companies-trust__wrapper'>
						<div className='companies-trust__title'>
							<h2>НАМ ДОВЕРЯЮТ</h2>
							<div className='companies-trust__title-decor'>
								<img src={Circle} alt='Circle' />
							</div>
						</div>
						<div className='companies-trust__decor'>
							<img
								className='companies-trust__decor-line1'
								src={Line1}
								alt='Line1'
							/>
							<img
								className='companies-trust__decor-line2'
								src={Line2}
								alt='Line2'
							/>
						</div>
						<div className='companies-trust__list-logos'>
							<Link to='https://www.aorus.com/ru-ru' target='_blank'>
								<img src={Aorus} alt='Aorus' />
							</Link>
							<Link to='https://www.lg.com/ru' target='_blank'>
								<img src={LgElectronics} alt='LgElectronics' />
							</Link>
							<Link to='https://sbermegamarket.ru/' target='_blank'>
								<img src={GoodsRu} alt='GoodsRu' />
							</Link>
							<Link to='https://mts.ru/' target='_blank'>
								<img src={MTS} alt='MTS' />
							</Link>
							<Link to='https://wasd.tv/' target='_blank'>
								<img src={WasdTV} alt='WasdTV' />
							</Link>
							<Link to='https://visa.com' target='_blank'>
								<img src={Visa} alt='Visa' />
							</Link>
							<Link to='https://www.faceit.com/' target='_blank'>
								<img src={Faceit} alt='Faceit' />
							</Link>
							<Link to='https://www.gigabyte.ru/' target='_blank'>
								<img src={Gigabyte} alt='Gigabyte' />
							</Link>
							<Link to='https://sbermegamarket.ru/' target='_blank'>
								<img src={Sbermm} alt='Sbermm' />
							</Link>
							<Link to='https://www.mi.com/ru' target='_blank'>
								<img src={Xiaomi} alt='Xiaomi' />
							</Link>
							<Link to='https://www.gazprombank.ru/' target='_blank'>
								<img src={Gazprombank} alt='Gazprombank' />
							</Link>
							<Link to='https://redragon.ru/' target='_blank'>
								<img src={Redragon} alt='Redragon' />
							</Link>
							<Link to='https://www.riotgames.com/ru' target='_blank'>
								<img src={RiotGames} alt='RiotGames' />
							</Link>
							<Link to='https://www.acer.com/ru-ru/' target='_blank'>
								<img src={Acer} alt='Acer' />
							</Link>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export { CompaniesTrust }
