import { Link } from 'react-router-dom'

import styles from './BurgerNavigation.module.scss'

import { AnchorLink } from '../AnchorLink'

import LogoMobile from '../../static/img/header/logo-mob.svg'

function BurgerNavigation(props: any) {
	return (
		<>
			<div
				className={`${styles.root} ${
					props.burgerActive ? styles.isActive : ''
				}`}
			>
				<Link to='/' className={styles.logo}>
					<img src={LogoMobile} alt='No Sleep Agency' />
				</Link>

				<div className={styles.list}>
					{/* <Link onClick={props.mobileMenu} to='/' className={styles.item}>
						Новости
					</Link> */}
					<div onClick={props.mobileMenu} className={styles.item}>
						<AnchorLink id='cases'>Кейсы</AnchorLink>
					</div>

					<Link onClick={props.mobileMenu} to='/' className={styles.item}>
						Услуги
					</Link>
					<Link onClick={props.mobileMenu} to='/' className={styles.item}>
						Разработки
					</Link>
					<Link
						onClick={props.mobileMenu}
						to='/contacts'
						className={styles.item}
					>
						Контакты
					</Link>
				</div>
			</div>
		</>
	)
}

export { BurgerNavigation }
