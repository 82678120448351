import './Awards.scss'
import '../CasesSlider/CasesSlider.scss'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import { AwardItem } from './AwardItem'
import { IconArrowRight } from '../../../static/img/svg/IconArrowRight'

import Award1 from '../../../static/img/awards/1.svg'
import Award2 from '../../../static/img/awards/2.svg'
import Award3 from '../../../static/img/awards/3.svg'
import Award4 from '../../../static/img/awards/4.svg'
import Award5 from '../../../static/img/awards/Silver-Mercury-1.svg'
import TaglineLogo from '../../../static/img/awards/tagline_logo_awards.png'

// import Footer from '../../../static/img/awards/footer.svg'

function Awards() {
	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 2,
			slidesToSlide: 2,
			partialVisibilityGutter: 0,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 2,
			slidesToSlide: 2,
			partialVisibilityGutter: 0,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1,
			slidesToSlide: 1,
			partialVisibilityGutter: 0,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1,
			partialVisibilityGutter: 0,
		},
	}

	return (
		<>
			<section className='awards' id='awards'>
				<div className='container'>
					<div className='awards__title-wrp'>
						<h2 className='awards__title'>Награды</h2>
					</div>

					<Carousel
						sliderClass='awards-sliderClass'
						autoPlay={true}
						autoPlaySpeed={5600}
						responsive={responsive}
						rewind={true}
						rewindWithAnimation={true}
						renderArrowsWhenDisabled={true}
						customTransition={`transform 320ms ease-in`}
						transitionDuration={1060}
						partialVisible={true}
						customLeftArrow={
							<button
								className={`sl-nav__btn awards-carousel__nav--prev sl-nav__btn--prev`}
							>
								<IconArrowRight />
							</button>
						}
						customRightArrow={
							<button
								className={`sl-nav__btn awards-carousel__nav--next sl-nav__btn--next `}
							>
								<IconArrowRight />
							</button>
						}
					>
						<AwardItem
							header='silvermercury'
							image={Award5}
							title='БРОНЗА'
							awardName='2023 BEST MARKETING CAMPAIGN'
							footnote={`Best use of social media / Лучшее использование социальных сетей`}
						/>
						<AwardItem
							header='TAGLINE AWARDS'
							image={Award1}
							title='ЗОЛОТО'
							awardName='2020-2021'
							footnote='Лучшая ритейл-кампания'
							smLogo={TaglineLogo}
						/>
						<AwardItem
							header='TAGLINE AWARDS'
							image={Award2}
							title='СЕРЕБРО'
							awardName='2020-2021'
							footnote='Лучшая таргетированная кампания в социальных сетях'
							smLogo={TaglineLogo}
						/>
						<AwardItem
							header='TAGLINE AWARDS'
							image={Award3}
							title='СЕРЕБРО'
							awardName='2020-2021'
							footnote='Лучшая Social Media ритейл-компания'
							smLogo={TaglineLogo}
						/>
						<AwardItem
							header='TAGLINE AWARDS'
							image={Award4}
							title='БРОНЗА'
							awardName='2020-2021'
							footnote='Лучшее управление комьюнити'
							smLogo={TaglineLogo}
						/>
					</Carousel>

					<div className='cases-slider__controlls awards-slider__controlls'>
						{/* <div className='swiper-pagination'>
							<span className='swiper-pagination-current'>1</span> /{' '}
							<span className='swiper-pagination-total'>2</span>
						</div> */}
						<div className='sl-nav'>
							{/* <div
								className={`sl-nav__btn sl-nav__btn--prev`}
								role='button'
								aria-label='Previous slide'
								// aria-disabled={!isActive}
								// onClick={() => setIsActive(!isActive)}
							>
								<IconArrowRight />
							</div>
							<div
								className={`sl-nav__btn sl-nav__btn--next `}
								role='button'
								aria-label='Next slide'
								// aria-disabled={isActive}
								// onClick={() => setIsActive(!isActive)}
							>
								<IconArrowRight />
							</div> */}
						</div>
					</div>

					{/* <div className='awards-header'>
						<p className='awards-header__txt'>
							TAGLINE AWARDS <span>2020-2021</span>
						</p>
					</div>
					<div className='awards-grid'>
						<div className='awards-grid__item'>
							<span className='awards-grid__item-number'>01</span>
							<div className='awards-grid__item-img'>
								<img
									className='js-random-appear'
									src={Award1}
									alt='Award1'
									style={{
										opacity: 1,
										transform: `translate3d(0px, 0px, 0px)`,
									}}
								/>
							</div>
							<div className='awards-grid__item-txt'>
								<p>{`Лучшая\n ритейл-кампания`}</p>
							</div>
						</div>
						<div className='awards-grid__item'>
							<span className='awards-grid__item-number'>02</span>
							<div className='awards-grid__item-img'>
								<img
									className='js-random-appear'
									src={Award2}
									alt='Award2'
									style={{
										opacity: 1,
										transform: `translate3d(0px, 0px, 0px)`,
									}}
								/>
							</div>
							<div className='awards-grid__item-txt'>
								<p>{`Лучшая\n таргетированная\n кампания в соц.сетях`}</p>
							</div>
						</div>
						<div className='awards-grid__item'>
							<span className='awards-grid__item-number'>03</span>
							<div className='awards-grid__item-img'>
								<img
									className='js-random-appear'
									src={Award3}
									alt='Award3'
									style={{
										opacity: 1,
										transform: `translate3d(0px, 0px, 0px)`,
									}}
								/>
							</div>
							<div className='awards-grid__item-txt'>
								<p>{`Лучшая\n Social Media\n ритейл-компании`}</p>
							</div>
						</div>
						<div className='awards-grid__item'>
							<span className='awards-grid__item-number'>04</span>
							<div className='awards-grid__item-img'>
								<img
									className='js-random-appear'
									src={Award4}
									alt='Award4'
									style={{
										opacity: 1,
										transform: `translate3d(0px, 0px, 0px)`,
									}}
								/>
							</div>
							<div className='awards-grid__item-txt'>
								<p>{`Лучшее управление\n комьюнити`}</p>
							</div>
						</div>
					</div> */}
				</div>
				<div className='awards-lines'>
					<div className='awards-lines__item'>
						<div
							style={{
								width: 100000,
								transform: `translateX(0px)`,
								animation: `147.005s linear 0s infinite normal none running marqueeAnimation-7867789`,
							}}
							className='js-marquee-wrapper'
						>
							<div
								className='js-marquee'
								style={{ marginRight: 20, float: 'left' }}
							>
								<span>НАГРАДЫ NO SLEEP AGENCY //</span>
								<span>НАГРАДЫ NO SLEEP AGENCY //</span>
								<span>НАГРАДЫ NO SLEEP AGENCY //</span>
								<span>НАГРАДЫ NO SLEEP AGENCY //</span>
								<span>НАГРАДЫ NO SLEEP AGENCY //</span>
								<span>НАГРАДЫ NO SLEEP AGENCY //</span>
								<span>НАГРАДЫ NO SLEEP AGENCY //</span>
								<span>НАГРАДЫ NO SLEEP AGENCY //</span>
								<span>НАГРАДЫ NO SLEEP AGENCY //</span>
								<span>НАГРАДЫ NO SLEEP AGENCY //</span>
								<span>НАГРАДЫ NO SLEEP AGENCY //</span>
							</div>
							<div
								className='js-marquee'
								style={{ marginRight: 20, float: 'left' }}
							>
								<span>НАГРАДЫ NO SLEEP AGENCY //</span>
								<span>НАГРАДЫ NO SLEEP AGENCY //</span>
								<span>НАГРАДЫ NO SLEEP AGENCY //</span>
								<span>НАГРАДЫ NO SLEEP AGENCY //</span>
								<span>НАГРАДЫ NO SLEEP AGENCY //</span>
								<span>НАГРАДЫ NO SLEEP AGENCY //</span>
								<span>НАГРАДЫ NO SLEEP AGENCY //</span>
								<span>НАГРАДЫ NO SLEEP AGENCY //</span>
								<span>НАГРАДЫ NO SLEEP AGENCY //</span>
								<span>НАГРАДЫ NO SLEEP AGENCY //</span>
								<span>НАГРАДЫ NO SLEEP AGENCY //</span>
							</div>
						</div>
					</div>
					<div className='awards-lines__item'>
						<div
							style={{
								width: 100000,
								transform: `translateX(0px); animation: 142.162s linear 0s infinite normal none running marqueeAnimation-8643127`,
							}}
							className='js-marquee-wrapper'
						>
							<div
								className='js-marquee'
								style={{ marginRight: 20, float: 'left' }}
							>
								{' '}
								<span>НАГРАДЫ NO SLEEP AGENCY // </span>
								<span>НАГРАДЫ NO SLEEP AGENCY // </span>
								<span>НАГРАДЫ NO SLEEP AGENCY // </span>
								<span>НАГРАДЫ NO SLEEP AGENCY // </span>
								<span>НАГРАДЫ NO SLEEP AGENCY // </span>
								<span>НАГРАДЫ NO SLEEP AGENCY // </span>
								<span>НАГРАДЫ NO SLEEP AGENCY // </span>
								<span>НАГРАДЫ NO SLEEP AGENCY // </span>
								<span>НАГРАДЫ NO SLEEP AGENCY // </span>
								<span>НАГРАДЫ NO SLEEP AGENCY // </span>
								<span>НАГРАДЫ NO SLEEP AGENCY // </span>
							</div>
							<div
								className='js-marquee'
								style={{ marginRight: 20, float: 'left' }}
							>
								{' '}
								<span>НАГРАДЫ NO SLEEP AGENCY // </span>
								<span>НАГРАДЫ NO SLEEP AGENCY // </span>
								<span>НАГРАДЫ NO SLEEP AGENCY // </span>
								<span>НАГРАДЫ NO SLEEP AGENCY // </span>
								<span>НАГРАДЫ NO SLEEP AGENCY // </span>
								<span>НАГРАДЫ NO SLEEP AGENCY // </span>
								<span>НАГРАДЫ NO SLEEP AGENCY // </span>
								<span>НАГРАДЫ NO SLEEP AGENCY // </span>
								<span>НАГРАДЫ NO SLEEP AGENCY // </span>
								<span>НАГРАДЫ NO SLEEP AGENCY // </span>
								<span>НАГРАДЫ NO SLEEP AGENCY // </span>
							</div>
						</div>
					</div>
				</div>

				{/* <div className='container'>
					<div className='awards-footer'>
						<img src={Footer} alt='footer' />
					</div>
				</div> */}
			</section>
		</>
	)
}

export { Awards }
