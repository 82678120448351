import { useEffect } from 'react'

import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { Hero } from '../components/ContentBlocks/Hero'
import { AboutAs } from '../components/ContentBlocks/AboutAs'
import { Awards } from '../components/ContentBlocks/Awards'
import { CasesSlider } from '../components/ContentBlocks/CasesSlider'
import { ContactsBlock } from '../components/ContentBlocks/ContactsBlock'
import { CompaniesTrust } from '../components/ContentBlocks/CompaniesTrust'

function Home() {
	useEffect(() => {
		document.title = 'No Sleep Agency'
		window.scrollTo(0, 0)
	}, [])

	return (
		<>
			<div className='wrapper'>
				<Header />

				<main>
					<Hero />
					<AboutAs />
					<CasesSlider />
					<CompaniesTrust />
					<Awards />
					<ContactsBlock />
				</main>

				<Footer />
			</div>
		</>
	)
}

export default Home
