function IconArrowRight() {
	return (
		<>
			<svg
				width='19'
				height='14'
				viewBox='0 0 19 14'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M17.9939 7.59397C18.322 7.26593 18.322 6.73407 17.9939 6.40603L12.6482 1.0603C12.3202 0.732263 11.7883 0.732263 11.4603 1.0603C11.1322 1.38834 11.1322 1.9202 11.4603 2.24824L16.212 7L11.4603 11.7518C11.1322 12.0798 11.1322 12.6117 11.4603 12.9397C11.7883 13.2677 12.3202 13.2677 12.6482 12.9397L17.9939 7.59397ZM0.599976 7.84L17.4 7.84L17.4 6.16L0.599976 6.16L0.599976 7.84Z'
					fill='current'
				/>
			</svg>
		</>
	)
}

export { IconArrowRight }
