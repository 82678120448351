import styles from './FormTextarea.module.scss'

interface FormTextareaProps {
	placeholder?: string
	name?: string
	onChange?: React.ChangeEventHandler<HTMLInputElement>
	onClick?: React.MouseEventHandler<HTMLInputElement>
	register?: any
	id?: string
	error?: any
	value?: string
}

const FormTextarea: React.FC<FormTextareaProps> = ({
	placeholder,
	name,
	onChange,
	onClick,
	register,
	id,
	error,
}) => (
	<>
		<div className={styles.root}>
			<textarea
				className={`${styles.textarea} ${error ? styles.error : ''}`}
				placeholder={placeholder}
				name={name}
				onChange={onChange}
				onClick={onClick}
				id={id}
				{...register}
			></textarea>

			{error && (
				<h6 className={styles.errorValue}>
					{error?.message || 'Заполните это поле'}
				</h6>
			)}
		</div>
	</>
)

export { FormTextarea }
