import './Results.scss'

import LogoFks from '../../../static/img/results/logo-min.png'

function Results() {
	return (
		<>
			<section className='results'>
				<div className='container'>
					<div className='results__title-wrp'>
						<h2 className='results__title'>Результаты</h2>
					</div>
					<div className='results-grid'>
						<div className='results-grid__item'>
							<p className='results-grid__item-txt'>
								Общий охват всех интеграций <span>-</span>
							</p>
							<p className='results-grid__item-number'>850 000</p>
						</div>
						<div className='results-grid__item'>
							<p className='results-grid__item-txt'>
								Переходы для регистрации <span>-</span>
							</p>
							<p className='results-grid__item-number'>20 000</p>
						</div>
					</div>
					<div className='results-logo'>
						<img src={LogoFks} alt='ФКС' />
					</div>
				</div>
			</section>
		</>
	)
}

export { Results }
